import { CustomerJobEditComponent } from '../components/Customer/CustomerJobEditComponent'
import { getText } from '../components/SmallComponents/Translate'
import { useNavigate } from "@solidjs/router";
import { isLoggedIn } from '../../utils/isloggedin'

const ShowCustomerJobEditPage = () => {

    const navigate = useNavigate();

    if (!isLoggedIn()) {
      navigate('/login');
      return;
    }

    const txtHeader = getText("jobpage", "editjob") 
    return (
        <>
            <CustomerJobEditComponent header={txtHeader() as string}/> 
        </>
    );
}
export {ShowCustomerJobEditPage}